@import url(https://fonts.googleapis.com/css?family=Rubik:300,400,500,700);
.text-peach {
    color: #F99584;
}

.text-purple {
    color: #8B5FBF;
}
body {
    background-color:#2a2d31;
    color: white;
    font-family: 'Rubik', sans-serif;
}

.content {
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 140%;
}

.logo {
    height: 350px;
}

.header {
    background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4) ), url(/static/media/banner.acc563c6.png);
    background-position: center; /* Center the image */
    background-size: cover;
    padding: 30px;
}
.navbar {
    background: #1a5060;
}
.navigation a {
    text-decoration: none;
    font-size: 200%;
    font-weight: bolder;
    padding: 20px;
    color: #eaf5fb;
}

.navigation a:hover {
    text-decoration: none;
    color: #99d2eb;
}

.navigation a:active {
    text-decoration: none;
    color: #42c8f1;
}

.navigation ul {
    list-style: none;
    display: flex;
}
